import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import FormControl from "react-bootstrap/lib/FormControl";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Button from "react-bootstrap/lib/Button";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Select from "react-select";
import { ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import UserManagementHandler from "./UserManagementHandler";
import "./UserManagement.css";
import { DisplayMessages, ErrorMessages } from "../Utils/FetchConfigurableData";
import DistributorManagementHandler from "../DistributorManagement/DistributorManagementHandler";

const roleList = [
  { value: "360 Admin", label: "360 Admin" },
  { value: "Internal Salesrep", label: "Internal Salesrep" },
  { value: "Salesrep", label: "Salesrep" },
  { value: "Branch Admin", label: "Branch Admin" },
];
const salesRoleList = [
  { value: "360 Admin", label: "360 Admin" },
  { value: "Internal Salesrep", label: "Internal Salesrep" },
];
const yesNoList = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];
const branchBasedRoles = ["Distributor User", "Branch Admin"];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

/**
 * Component represents the edit user popup in user management subsection
 */
class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      firstName: "",
      lastName: "",
      emailid: "",
      selectedRole: "",
      selectedGoldenPriceAccess: "",
      selectedGPLabel: "",
      selectedPricingAccess: "",
      selectedPALabel: "",
      selectedOrderTracking: "",
      selectedOTLabel: "",
      selectedTldVisibility: "",
      selectedTVLabel: "",
      selectedAgency: "",
      salesAgencyList: [],
      selectedTld: "",
      selectedTldLabel: "",
      multiAdmin: false,
      tldProp: "",
      branchList: [],
      selectedBranch: "",
      selectedBranchLabel: "",
      branchProp: "",
      phone: "",
      jobTitle: "",
      rowdata: [],
      tldList: [],
      currentUser: "",
      allowEdit: false,
    };
    this._notificationSystem = null;
    this.handleEditClose = this.handleEditClose.bind(this);
    this.setFirstName = this.setFirstName.bind(this);
    this.setLastName = this.setLastName.bind(this);
    this.generateRoleOptions = this.generateRoleOptions.bind(this);
    this.setRole = this.setRole.bind(this);
    this.generateSalesrepFields = this.generateSalesrepFields.bind(this);
    this.generateBranchAdminFields = this.generateBranchAdminFields.bind(this);
    this.setSalesrepVisibility = this.setSalesrepVisibility.bind(this);
    this.setBranchAdminVisibility = this.setBranchAdminVisibility.bind(this);
    this.generateSalesAgency = this.generateSalesAgency.bind(this);
    this.setAgency = this.setAgency.bind(this);
    this.reset = this.reset.bind(this);
    this.setTld = this.setTld.bind(this);
    this.generateTldOptions = this.generateTldOptions.bind(this);
    this.setTldVisibility = this.setTldVisibility.bind(this);
    this.setOrderTracking = this.setOrderTracking.bind(this);
    this.setPricingAccess = this.setPricingAccess.bind(this);
    this.setGoldenPriceAccess = this.setGoldenPriceAccess.bind(this);
    this.getBranch = this.getBranch.bind(this);
    this.setBranch = this.setBranch.bind(this);
    this.generateBranchOptions = this.generateBranchOptions.bind(this);
    this.setEmailId = this.setEmailId.bind(this);
    this.setPhoneNumber = this.setPhoneNumber.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.setJobTitle = this.setJobTitle.bind(this);
    this.setUserRole = this.setUserRole.bind(this);
    this.update = this.update.bind(this);
    this.cancel = this.cancel.bind(this);
    this.freshUpdate = this.freshUpdate.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rowdata.userName !== undefined) {
      this.setState(
        {
          rowdata: nextProps.rowdata,
          userName: nextProps.rowdata.userName,
          firstName: nextProps.rowdata.firstName,
          lastName: nextProps.rowdata.lastName,
          selectedRole: nextProps.rowdata.role,
          selectedPricingAccess: nextProps.rowdata.pricingAccess,
          selectedPALabel: this.setAccess(nextProps.rowdata.pricingAccess),
          selectedGoldenPriceAccess: nextProps.rowdata.goldenPriceAccess,
          selectedGPLabel: this.setAccess(nextProps.rowdata.goldenPriceAccess),
          selectedOrderTracking: nextProps.rowdata.orderTracking,
          selectedOTLabel: this.setAccess(nextProps.rowdata.orderTracking),
          selectedTldVisibility: nextProps.rowdata.tldVisibility,
          selectedTVLabel: this.setAccess(nextProps.rowdata.tldVisibility),
          multiAdmin: nextProps.rowdata.multiAdmin,
          emailid: nextProps.rowdata.userName,
          tldProp: nextProps.rowdata.tld,
          branchProp: nextProps.rowdata.branch,
          phone: nextProps.rowdata.phoneNumber,
          jobTitle: nextProps.rowdata.jobTitle,
          currentUser: sessionStorage.getItem("username"),
          allowEdit: false,
        },
        () => {
          if (this.state.selectedRole === "Salesrep") {
            UserManagementHandler.getSalesAgency(
              encodeURIComponent(this.state.userName)
            )
              .then((response) => {
                this.setState({
                  selectedAgency: response.message,
                });
              })
              .catch((error) => {
                this.addNotification(
                  ErrorMessages.AddUser_SalesAgency,
                  "error"
                );
              });
          }

          if (
            this.state.branchProp !== "-" &&
            this.state.branchProp !== undefined
          ) {
            UserManagementHandler.getBranchId(
              encodeURIComponent(this.state.branchProp)
            )
              .then((response) => {
                this.setState({
                  selectedBranch: response.message,
                });
              })
              .catch((error) => {
                this.addNotification(ErrorMessages.EditUser_BranchId, "error");
              });
          }
        }
      );
    }
  }

  setAccess(access) {
    return access === "yes" ? "Yes" : "No";
  }

  ternaryEvaluation(value1, value2, condition1, condition2) {
    return value1 === value2 ? condition1 : condition2;
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.handleOnClose}
          className="add-user-modal"
        >
          <Modal.Header>
            <h3
              style={{ fontWeight: "bolder", paddingLeft: "15px" }}
              className="popup-blue-text"
            >
              Edit User
            </h3>
          </Modal.Header>
          <Modal.Body style={{ minHeight: "250px" }}>
            <Row>
              <Col md={4}>
                <h4>
                  First Name<mark>*</mark>
                </h4>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormControl
                    type="text"
                    maxLength={50}
                    style={{ color: "black" }}
                    value={this.state.firstName}
                    placeholder="Enter First Name"
                    onChange={this.setFirstName}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h4>
                  Last Name<mark>*</mark>
                </h4>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormControl
                    type="text"
                    style={{ color: "black" }}
                    maxLength={50}
                    value={this.state.lastName}
                    placeholder="Enter Last Name"
                    onChange={this.setLastName}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h4>Email ID</h4>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormControl
                    type="text"
                    style={{ color: "black" }}
                    disabled
                    value={this.state.emailid}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h4>
                  Job Title<mark>*</mark>
                </h4>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormControl
                    type="text"
                    style={{ color: "black" }}
                    maxLength={50}
                    value={this.state.jobTitle}
                    placeholder="Enter Job Title"
                    onChange={this.setJobTitle}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h4>Phone Number</h4>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormControl
                    type="text"
                    style={{ color: "black" }}
                    maxLength={20}
                    value={this.state.phone === "-" ? "" : this.state.phone}
                    placeholder="Enter Phone Number"
                    onChange={this.setPhoneNumber}
                  />
                </FormGroup>
              </Col>
            </Row>
            {this.setUserRole()}
            {this.generateSalesrepFields()}
            {this.generateBranchAdminFields()}
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <ButtonToolbar style={{ display: "flex" }}>
                <Col md={3}>
                  <Button
                    bsStyle="primary"
                    style={{ width: "100%", height: "100%" }}
                    disabled={!this.state.allowEdit}
                    onClick={this.update}
                    className="button-blue"
                  >
                    Save Changes
                  </Button>
                </Col>
                <Col md={3}>
                  <Button
                    bsStyle="primary"
                    style={{ width: "100%", height: "100%" }}
                    onClick={this.cancel}
                    className="button-white"
                  >
                    Cancel
                  </Button>
                </Col>
              </ButtonToolbar>
            </Row>
          </Modal.Footer>
        </Modal>
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  setUserRole() {
    if (
      (this.state.rowdata.role !== roleList[0].value &&
        this.state.rowdata.role !== roleList[1].value) ||
      this.state.rowdata.userName === this.state.currentUser
    ) {
      return (
        <Row>
          <Col md={4}>
            <h4>
              Role<mark>*</mark>
            </h4>
          </Col>
          <Col md={6}>
            <FormGroup>
              <FormControl
                type="text"
                style={{ color: "black" }}
                disabled
                value={this.state.selectedRole}
              />
            </FormGroup>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col md={4}>
            <h4>
              Role<mark>*</mark>
            </h4>
          </Col>
          <Col md={6}>
            <Select
              maxMenuHeight={190}
              menuPlacement="auto"
              value={{
                value: this.state.selectedRole,
                label:
                  this.state.selectedRole === null ||
                  this.state.selectedRole === ""
                    ? "Select..."
                    : this.state.selectedRole,
              }}
              styles={customStyles}
              isSearchable
              onChange={this.setRole}
              options={salesRoleList}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "lightgray",
                  primary: "lightgray",
                },
              })}
            />
          </Col>
        </Row>
      );
    }
  }

  setJobTitle(e) {
    this.setState(
      {
        jobTitle: e.target.value,
      },
      this.freshUpdate
    );
  }

  cancel() {
    this.trackCancelButtonClick();
    this.props.handleOnClose();
  }

  freshUpdate() {
    let changesMade = 0;
    if (this.state.firstName !== this.state.rowdata.firstName) {
      changesMade++;
    }
    if (this.state.lastName !== this.state.rowdata.lastName) {
      changesMade++;
    }
    if (this.state.jobTitle !== this.state.rowdata.jobTitle) {
      changesMade++;
    }
    if (this.state.phone !== this.state.rowdata.phoneNumber) {
      changesMade++;
    }
    if (this.state.selectedRole !== this.state.rowdata.role) {
      changesMade++;
    }
    if (this.state.selectedPricingAccess !== this.state.rowdata.pricingAccess) {
      changesMade++;
    }
    if (
      this.state.selectedPricingAccess === "yes" &&
      this.state.selectedGoldenPriceAccess !==
        this.state.rowdata.goldenPriceAccess
    ) {
      changesMade++;
    }
    if (this.state.selectedOrderTracking !== this.state.rowdata.orderTracking) {
      changesMade++;
    }
    if (this.state.selectedTldVisibility !== this.state.rowdata.tldVisibility) {
      changesMade++;
    }

    if (changesMade > 0) {
      this.setState({
        allowEdit: true,
      });
    } else {
      this.setState({
        allowEdit: false,
      });
    }
  }

  update() {
    if (this.state.firstName === null || this.state.firstName.trim() === "") {
      this.trackSaveChangesFailedButtonClick(ErrorMessages.AddUser_FirstName);
      this.addNotification(ErrorMessages.AddUser_FirstName, "warning");
      return;
    }
    if (this.state.lastName === null || this.state.lastName.trim() === "") {
      this.trackSaveChangesFailedButtonClick(ErrorMessages.AddUser_LastName);
      this.addNotification(ErrorMessages.AddUser_LastName, "warning");
      return;
    }
    if (this.state.jobTitle === null || this.state.jobTitle.trim() === "") {
      this.trackSaveChangesFailedButtonClick(ErrorMessages.AddUser_JobTitle);
      this.addNotification(ErrorMessages.AddUser_JobTitle, "warning");
      return;
    }
    if (
      this.state.selectedRole === roleList[3].value &&
      this.state.selectedBranch === ""
    ) {
      this.trackSaveChangesFailedButtonClick(ErrorMessages.AddUser_Branch);
      this.addNotification(ErrorMessages.AddUser_Branch, "warning");
      return;
    }
    let data = {
      userName: this.state.userName,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.emailid,
      phoneNumber: this.state.phone,
      previousRole: this.state.rowdata.role,
      role: this.state.selectedRole,
      jobTitle: this.state.jobTitle,
      agency:
        this.state.selectedRole === roleList[2].value ||
        this.state.selectedRole === branchBasedRoles[0]
          ? this.state.selectedAgency
          : "",
      tld: branchBasedRoles.includes(this.state.selectedRole)
        ? this.state.selectedTld
        : "",
      branch: branchBasedRoles.includes(this.state.selectedRole)
        ? this.state.selectedBranch
        : "",
      tldVisibility: branchBasedRoles.includes(this.state.selectedRole)
        ? this.state.selectedTldVisibility
        : "",
      orderTracking: branchBasedRoles.includes(this.state.selectedRole)
        ? this.state.selectedOrderTracking
        : "",
      pricingAccess: branchBasedRoles.includes(this.state.selectedRole)
        ? this.state.selectedPricingAccess
        : "",
      goldenPriceAccess: branchBasedRoles.includes(this.state.selectedRole)
        ? this.state.selectedGoldenPriceAccess
        : "",
    };

    UserManagementHandler.updateUser(data)
      .then((response) => {
        if (response.success) {
          if (this.state.currentUser === this.state.userName) {
            sessionStorage.setItem("firstName", this.state.firstName);
            sessionStorage.setItem("lastName", this.state.lastName);
            sessionStorage.setItem("userRole", this.state.selectedRole);
          }
          this.trackSaveChangesButtonClick(data);
        } else {
          this.trackSaveChangesFailedButtonClick(response.message);
          this.addNotification(response.message, "error");
        }
        this.handleEditClose();
      })
      .catch((error) => {
        this.trackSaveChangesFailedButtonClick(
          ErrorMessages.EditUser_FailedEditUser
        );
        this.addNotification(ErrorMessages.EditUser_FailedEditUser, "error");
      });
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  setEmailId(e) {
    this.setState({
      emailid: e.target.value,
    });
  }

  setPhoneNumber(e) {
    let phone = e.target.value;
    this.setState(
      {
        phone: phone,
      },
      this.freshUpdate
    );
  }

  generateSalesrepFields() {
    if (this.state.rowdata.role === roleList[2].value) {
      return (
        <div className={this.setSalesrepVisibility()}>
          <Row>
            <Col md={4}>
              <h4>
                Sales Agency<mark>*</mark>
              </h4>
            </Col>
            <Col md={6}>
              <FormGroup>
                <FormControl
                  type="text"
                  style={{ color: "black" }}
                  disabled
                  value={this.state.selectedAgency}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div className={this.setSalesrepVisibility()}>
          <Row>
            <Col md={4}>
              <h4>
                Sales Agency<mark>*</mark>
              </h4>
            </Col>
            <Col md={6}>
              <Select
                maxMenuHeight={190}
                menuPlacement="auto"
                value={{
                  value: this.state.selectedAgency,
                  label:
                    this.state.selectedAgency === null ||
                    this.state.selectedAgency === ""
                      ? "Select..."
                      : this.state.selectedAgency,
                }}
                styles={customStyles}
                isSearchable
                onChange={this.setAgency}
                options={this.state.salesAgencyList}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgray",
                    primary: "lightgray",
                  },
                })}
              />
            </Col>
          </Row>
        </div>
      );
    }
  }

  generateTldOptions() {
    return this.state.tldList?.map((item, i) => (
      <option key={item.Id} value={item.Id}>
        {item.TldName}
      </option>
    ));
  }

  getBranch(tld) {
    UserManagementHandler.getBranch(tld)
      .then((response) => {
        if (response.data.length === 0) {
          this.addNotification(ErrorMessages.AddUser_NoBranch, "warning");
        }
        this.setState({
          branchList: response.data.map((item) => ({
            value: item.BranchId,
            label: item.BranchName,
          })),
          selectedBranch:
            response.data[0] !== undefined ? response.data[0].BranchId : "",
          selectedBranchLabel:
            response.data[0] !== undefined ? response.data[0].BranchName : "",
        });
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.AddUser_GetBranch, "error");
      });
  }

  setTld(event) {
    if (event == null) {
      this.getBranch("");
      this.setState(
        {
          selectedTld: "",
          selectedTldLabel: "",
        },
        this.freshUpdate
      );
    } else {
      this.getBranch(event.value);
      this.setState(
        {
          selectedTldLabel: event.label,
          selectedTld: event.value,
        },
        this.freshUpdate
      );
    }
  }

  generateSalesAgency() {
    return this.state.salesAgencyList?.map((item, i) => (
      <option key={item.Name} value={item.Name}>
        {item.Name}
      </option>
    ));
  }

  setAgency(event) {
    if (event == null) {
      this.setState(
        {
          selectedAgency: "",
        },
        this.freshUpdate
      );
    } else {
      this.setState(
        {
          selectedAgency: event.value,
        },
        this.freshUpdate
      );
    }
  }

  setBranch(event) {
    if (event == null) {
      this.setState(
        {
          selectedBranch: "",
          selectedBranchLabel: "",
        },
        this.freshUpdate
      );
    } else {
      this.setState(
        {
          selectedBranch: event.value,
          selectedBranchLabel: event.label,
        },
        this.freshUpdate
      );
    }
  }

  generateBranchOptions() {
    return this.state.branchList?.map((item, i) => (
      <option key={item.BranchId} value={item.BranchId}>
        {item.BranchName}
      </option>
    ));
  }

  generateBranchAdminFields() {
    if (
      this.state.tldProp !== "-" ||
      branchBasedRoles.includes(this.state.rowdata.role)
    ) {
      return (
        <div>
          <Row>
            <Col md={4}>
              <h4>
                Select TLD<mark>*</mark>
              </h4>
            </Col>
            <Col md={6}>
              <FormGroup>
                <FormControl
                  type="text"
                  style={{ color: "black" }}
                  disabled
                  value={this.state.tldProp === "-" ? "" : this.state.tldProp}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <h4>
                Select Branch<mark>*</mark>
              </h4>
            </Col>
            <Col md={6}>
              <FormGroup>
                <FormControl
                  type="text"
                  style={{ color: "black" }}
                  disabled
                  value={
                    this.state.branchProp === "-" ? "" : this.state.branchProp
                  }
                />
              </FormGroup>
            </Col>
          </Row>

          {this.pricingAccessDropdown()}

          {this.orderTrackingDropdown()}

          {this.tldVisibilityDropdown()}

          {this.goldenPriceAccessDropdown()}
        </div>
      );
    } else {
      return (
        <div className={this.setBranchAdminVisibility()}>
          <Row>
            <Col md={4}>
              <h4>
                Select TLD<mark>*</mark>
              </h4>
            </Col>
            <Col md={4}>{this.tldDropdown()}</Col>
          </Row>
          <Row>
            <Col md={4}>
              <h4>
                Select Branch<mark>*</mark>
              </h4>
            </Col>
            <Col md={4}>{this.branchDropdown()}</Col>
          </Row>
          {this.tldVisibilityDropdown()}
        </div>
      );
    }
  }

  tldDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.selectedTldLabel,
          label:
            this.state.selectedTldLabel === null ||
            this.state.selectedTldLabel === ""
              ? "Select..."
              : this.state.selectedTldLabel,
        }}
        styles={customStyles}
        isSearchable
        onChange={this.setTld}
        options={this.state.tldList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  branchDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        value={{
          value: this.state.selectedBranchLabel,
          label:
            this.state.selectedBranchLabel === null ||
            this.state.selectedBranchLabel === ""
              ? "Select..."
              : this.state.selectedBranchLabel,
        }}
        styles={customStyles}
        isSearchable
        onChange={this.setBranch}
        options={this.state.branchList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  pricingAccessDropdown() {
    return (
      <Row>
        <Col md={4}>
          <h4>
            Pricing Access<mark>*</mark>
          </h4>
        </Col>
        <Col md={6}>
          <Select
            maxMenuHeight={190}
            menuPlacement="auto"
            value={{
              value: this.state.selectedPALabel,
              label:
                this.state.selectedPALabel === null ||
                this.state.selectedPALabel === ""
                  ? "Select..."
                  : this.state.selectedPALabel,
            }}
            styles={customStyles}
            isSearchable
            onChange={this.setPricingAccess}
            options={yesNoList}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "lightgray",
                primary: "lightgray",
              },
            })}
          />
        </Col>
      </Row>
    );
  }

  goldenPriceAccessDropdown() {
    if (this.state.selectedPricingAccess === "yes") {
      return (
        <Row>
          <Col md={4}>
            <h4>
              Gold Price<mark>*</mark>
            </h4>
          </Col>
          <Col md={6}>
            <Select
              maxMenuHeight={190}
              menuPlacement="auto"
              value={{
                value: this.state.selectedGPLabel,
                label:
                  this.state.selectedGPLabel === null ||
                  this.state.selectedGPLabel === ""
                    ? "Select..."
                    : this.state.selectedGPLabel,
              }}
              styles={customStyles}
              isSearchable
              onChange={this.setGoldenPriceAccess}
              options={yesNoList}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "lightgray",
                  primary: "lightgray",
                },
              })}
            />
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }

  orderTrackingDropdown() {
    return (
      <Row>
        <Col md={4}>
          <h4>
            Order Tracking<mark>*</mark>
          </h4>
        </Col>
        <Col md={6}>
          <Select
            maxMenuHeight={190}
            menuPlacement="auto"
            value={{
              value: this.state.selectedOTLabel,
              label:
                this.state.selectedOTLabel === null ||
                this.state.selectedOTLabel === ""
                  ? "Select..."
                  : this.state.selectedOTLabel,
            }}
            styles={customStyles}
            isSearchable
            onChange={this.setOrderTracking}
            options={yesNoList}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "lightgray",
                primary: "lightgray",
              },
            })}
          />
        </Col>
      </Row>
    );
  }

  tldVisibilityDropdown() {
    return (
      <Row>
        <Col md={4}>
          <h4>
            TLD Visibility<mark>*</mark>
            {this.state.multiAdmin ? (
              <div style={{ float: "right" }}>
                <OverlayTrigger
                  trigger={["click", "hover", "focus"]}
                  rootClose
                  show={this.state.multiAdmin}
                  placement="top"
                  overlay={
                    <Tooltip style={{ opacity: 1 }}>
                      <div>
                        {DisplayMessages.EditUser_BranchVisibilityCaution}
                      </div>
                    </Tooltip>
                  }
                >
                  <span
                    className="glyphicon glyphicon-info-sign"
                    style={{ fontSize: "16px" }}
                  />
                </OverlayTrigger>
              </div>
            ) : null}
          </h4>
        </Col>
        <Col md={6}>
          <Select
            maxMenuHeight={190}
            menuPlacement="auto"
            isDisabled={this.state.multiAdmin}
            value={{
              value: this.state.selectedTVLabel,
              label:
                this.state.selectedTVLabel === null ||
                this.state.selectedTVLabel === ""
                  ? "Select..."
                  : this.state.selectedTVLabel,
            }}
            styles={customStyles}
            isSearchable
            onChange={this.setTldVisibility}
            options={yesNoList}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "lightgray",
                primary: "lightgray",
              },
            })}
          />
        </Col>
      </Row>
    );
  }

  setTldVisibility(event) {
    this.setState(
      {
        selectedTldVisibility: event.value,
        selectedTVLabel: event.label,
      },
      this.freshUpdate
    );
  }

  setOrderTracking(event) {
    this.setState(
      {
        selectedOrderTracking: event.value,
        selectedOTLabel: event.label,
      },
      this.freshUpdate
    );
  }

  setPricingAccess(event) {
    if (event.value === "no") {
      this.setState({
        selectedGoldenPriceAccess: event.value,
        selectedGPLabel: event.label,
      });
    }
    this.setState(
      {
        selectedPricingAccess: event.value,
        selectedPALabel: event.label,
      },
      this.freshUpdate
    );
  }

  setGoldenPriceAccess(event) {
    this.setState(
      {
        selectedGoldenPriceAccess: event.value,
        selectedGPLabel: event.label,
      },
      this.freshUpdate
    );
  }

  setSalesrepVisibility() {
    if (
      this.state.selectedRole !== undefined &&
      this.state.selectedRole !== roleList[2].value
    ) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }

  setBranchAdminVisibility() {
    if (
      this.state.selectedRole !== undefined &&
      this.state.selectedRole !== roleList[3].value
    ) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }

  setRole(event) {
    if (event.value === roleList[3].value) {
      DistributorManagementHandler.getTldList()
        .then((response) => {
          this.setState(
            {
              tldList: response.data.map((item) => ({
                value: item.Id,
                label: item.TldName,
              })),
              selectedTld: response.data[0].Id,
              selectedTldLabel: response.data[0].TldName,
            },
            () => this.getBranch(this.state.selectedTld)
          );
        })
        .catch((error) => {
          this.addNotification(ErrorMessages.AddUser_TldList, "error");
        });
    }
    if (event.value === roleList[2].value) {
      DistributorManagementHandler.getSalesAgencyList()
        .then((response) => {
          this.setState({
            salesAgencyList: response.data.map((item) => ({
              value: item.Name,
              label: item.Name,
            })),
            selectedAgency: response.data[0].Name,
          });
        })
        .catch((error) => {
          this.addNotification(ErrorMessages.AddUser_SalesAgencyList, "error");
        });
    }
    this.setState(
      {
        selectedRole: event.value,
      },
      this.freshUpdate
    );
  }

  generateRoleOptions() {
    let newRoleList = roleList.filter(
      (x) => x.match(roleList[0].label) || x.match(roleList[1].label)
    );

    return newRoleList.map((item, i) => (
      <option key={item} value={item}>
        {item}
      </option>
    ));
  }

  reset() {
    this.setState((prevState) => ({
      firstName: prevState.rowdata.firstName,
      lastName: prevState.rowdata.lastName,
      selectedRole: prevState.rowdata.role,
      selectedTld: prevState.rowdata.tld,
      selectedTldLabel: prevState.rowdata.tld,
      selectedBranch: prevState.rowdata.branch,
      selectedBranchLabel: prevState.rowdata.branch,
      phone: prevState.rowdata.phoneNumber,
      jobTitle: prevState.rowdata.jobTitle,
      selectedPALabel: prevState.rowdata.pricingAccess !== "no" ? "Yes" : "No",
      selectedOTLabel: prevState.rowdata.orderTracking !== "no" ? "Yes" : "No",
      selectedTVLabel: prevState.rowdata.tldVisibility !== "no" ? "Yes" : "No",
      selectedGPLabel:
        prevState.rowdata.goldenPriceAccess !== "no" ? "Yes" : "No",
      selectedOrderTracking: prevState.rowdata.orderTracking,
      selectedPricingAccess: prevState.rowdata.pricingAccess,
      selectedTldVisibility: prevState.rowdata.tldVisibility,
      selectedGoldenPriceAccess: prevState.rowdata.goldenPriceAccess,
    }));
  }

  handleEditClose() {
    this.setState(
      {
        allowEdit: false,
      },
      this.props.resetUserTable
    );
  }

  setFirstName(e) {
    let firstName = e.target.value;
    firstName = firstName.replace(/[^a-zA-Z0-9_-]+/g, "");
    this.setState(
      {
        firstName: firstName,
      },
      this.freshUpdate
    );
  }

  setLastName(e) {
    let lastName = e.target.value;
    lastName = lastName.replace(/[^a-zA-Z0-9_-]+/g, "");
    this.setState(
      {
        lastName: lastName,
      },
      this.freshUpdate
    );
  }

  // Mixpanel Tracking //

  trackSaveChangesButtonClick(data) {
    mixpanel.track("Edit User Confirmation", {
      Action: "Button Click",
      Effect: "User details updated",
      "First Name": data.firstName,
      "Last Name": data.lastName,
      Email: data.email,
      "Phone Number": data.phoneNumber,
      "Previous Job Role": data.previousRole,
      "Current Job Role": data.role,
      "Job Title": data.jobTitle,
      "Sales Agency": data.agency,
      TLD: data.tld,
      Branch: data.branch,
      "TLD Visibility": data.tldVisibility,
      "Order Track Access": data.orderTracking,
      "Pricing Access": data.pricingAccess,
      "Golden Price Access": data.goldenPriceAccess,
    });
  }

  trackSaveChangesFailedButtonClick(message) {
    mixpanel.track("Edit User", {
      Action: "Button Click",
      Effect: "User update failed",
      Message: message,
    });
  }

  trackCancelButtonClick() {
    mixpanel.track("Edit User Cancellation", {
      Action: "Button Click",
      Effect: "User update cancelled",
    });
  }
}
export default EditUser;
